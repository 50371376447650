<template>
  <div>
    <!-- 检测数据 -->
    <div class="stateBox">
      <div class="stateBoxLeft">
        <div :style="draftStatus >= 3 ? 'color:#1890ff;' : ''">
          数据检测:
          <span v-if="draftStatus <= 1">待检测</span>
          <span v-if="draftStatus == 2">检查未通过</span>
          <span v-if="draftStatus >= 3">已通过</span>
          <!-- <el-button v-throttle 
          size="medium"
          :type="this.draftStatus >= 3 ? 'info' :'primary'"
          @click="testDataBtn"
          :disabled="this.draftStatus >= 3"
        >检查数据</el-button>-->
          <!-- 说明框 -->
          <template v-if="errorText.length && draftStatus == 2">
            <div class="illustrateBOX">
              <div style="margin-right: 10px">检测问题:</div>
              <div class="illustrateText">
                <div
                  style="margin-bottom: 10px"
                  v-for="(val, i) in errorText"
                  :key="val.name"
                >
                  <span>{{ i + 1 + '.' + val.name }}</span>
                  <p>{{ val.text }}</p>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div :style="draftStatus >= 4 ? 'color:#1890ff;' : ''">
          排考状态:
          <span v-if="draftStatus <= 3">待排考</span>
          <span v-else>已排考</span>
          <!-- <el-button v-throttle 
          size="medium"
          v-if="draftStatus <= 3"
          :disabled="this.draftStatus != 3"
          :type="this.draftStatus == 3 ? 'primary' :'info'"
          @click="arrangeBtn"
        >安排考试</el-button>
        <el-button v-throttle 
          size="medium"
          v-else
          :disabled="this.draftStatus != 4"
          :type="this.draftStatus == 4 ? 'danger' :'info'"
          @click="arrangeCancelBtn"
        >取消排考</el-button>-->
        </div>
        <div :style="draftStatus == 5 ? 'color:#1890ff;' : ''">
          发布状态:
          <span v-if="draftStatus != 5">待发布</span>
          <span v-else>已发布</span>
          <!-- <el-button v-throttle 
          size="medium"
          :disabled="draftStatus < 4 || draftStatus == 5"
          :type="this.draftStatus == 4 ? 'primary' :'info'"
          @click="releaseBtn"
        >发布考试</el-button>-->
          <span
            v-if="draftStatus == 5 && makeup"
            style="
              width: auto;
              margin-left: 20px;
              font-size: 14px;
              color: #f5a245;
            "
            >(发布考试后，考题与座位关联信息沿用正常考试的配置，请注意检查)
          </span>
        </div>
        <el-button
          v-throttle
          size="medium"
          type="primary"
          v-if="draftStatus >= 4"
          @click="openDownloadExamDataWindows"
          >下载资料</el-button
        >
        <!-- 成绩下载资料弹窗 -->
        <el-dialog
          :visible.sync="examDataDownloadDialogVisible"
          width="420px"
          :close-on-click-modal="false"
          title="下载资料"
          center
        >
          <div>请选择预下载的资料</div>
          <div>
            <el-checkbox-group
              style="display: inline; margin-right: 20px"
              v-model="examDataDownloadList"
            >
              <div class="examDataDownLoadBox">
                <el-checkbox :label="1">基本信息及考试规则</el-checkbox>
              </div>
              <div class="examDataDownLoadBox">
                <el-checkbox :label="2">考场安排</el-checkbox>
              </div>
              <div class="examDataDownLoadBox">
                <el-checkbox :label="3">监考安排</el-checkbox>
              </div>
              <div class="examDataDownLoadBox">
                <el-checkbox :label="4">考生安排</el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
          <div class="examDataButton">
            <el-button v-throttle type="primary" @click="downloadExamData"
              >下载</el-button
            >
            <el-button
              v-throttle
              type="danger"
              @click="closeDownloadExamDataWindows"
              >取消</el-button
            >
          </div>
        </el-dialog>
        <el-button
          v-throttle
          style="margin-left: 20px"
          size="medium"
          type="primary"
          v-if="draftStatus >= 4"
          @click="downloadTicketsDialog"
          >下载准考证</el-button
        >
        <el-button
          v-throttle
          style="margin-left: 20px"
          size="medium"
          type="primary"
          v-if="draftStatus >= 4"
          @click="compressDownloadTicketsFunc"
          >压缩下载准考证</el-button
        >
        <router-link to="/exam/examManage" replace style="margin-left: 20px">
          <el-button v-throttle size="medium">返回</el-button>
        </router-link>
      </div>
      <div class="stateBoxRight">
        <div class="stateBoxRightTit">资源占比情况</div>
        <div class="stateBoxRightList">
          <div class="stateBoxRightLi">
            <span v-if="draftStatus <= 3" style="color: #f56c6c">待排考</span>
            <span v-else style="color: #f56c6c">{{ this.$store.state.examResourceOccupation.exam }}GB</span>
            <div>预计该考试占用</div>
          </div>
          <div class="stateBoxRightLi">
            <span v-if="draftStatus <= 3" style="color: #409eff">待排考</span>
            <span v-else style="color: #409eff">{{ this.$store.state.examResourceOccupation.venue }}GB</span>
            <div>预计每个考场占用</div>
          </div>
          <div class="stateBoxRightLi">
            <span v-if="draftStatus <= 3" style="color: #67c23a">待排考</span>
            <span v-else style="color: #67c23a">{{ this.$store.state.examResourceOccupation.machine }}GB</span>
            <div>预计学生机占用</div>
          </div>
          <div class="stateBoxRightLi" v-show="!makeup">
            <span v-if="draftStatus <= 3" style="color: #e6a23c">待排考</span>
            <span v-else style="color: #e6a23c">{{ this.$store.state.examResourceOccupation.people }}人</span>
            <div>考生总人数</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <div
      class="cardBox"
      v-for="(item, i) in comList"
      :key="item.com + draftStatus"
    >
      <div class="cardTit">
        <!-- el-icon-arrow-up -->
        <i
          :class="item.show ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
          @click="toggleBtn(i)"
        ></i>
        <!-- <i class="el-icon-arrow-down" @click="hideBtn(i)" v-if="item.show"></i>
        <i class="el-icon-arrow-up" @click="showBtn(i)" v-else></i>-->
        <span>{{ i + 1 }}</span>
        {{ item.name }}
      </div>
      <div class="cardContent" :style="item.show ? '' : 'height:0'">
        <component
          :is="item.com"
          :arrangeStatus="arrangeStatus"
          :rulesArrangeModel="rulesArrangeModel"
          :draftStatus="draftStatus"
        ></component>
      </div>
    </div>
    <!--  -->
    <el-dialog
      title="下载准考证"
      :visible.sync="treeDialogVisible"
      width="500px"
      center
    >
      <!-- 树 -->
      <div style="margin-bottom: 10px">请选择需要下载的班级</div>
      <div style="padding: 0 20px; overflow-y: auto; height: 200px">
        <el-tree
          :data="treeList"
          show-checkbox
          default-expand-all
          node-key="sysOrgSchoolClassId"
          :default-checked-keys="allSysOrgSchoolClassId"
          :props="defaultProps"
          ref="elTree"
        >
        </el-tree>
      </div>
      <!-- 规格 -->
      <div style="margin: 20px 0 10px 0">请选择准考纸张规格</div>
      <div style="padding: 0 20px">
        <el-radio-group v-model="pdfSize">
          <el-radio :label="4">A4纸张</el-radio>
          <el-radio :label="5">A5纸张</el-radio>
          <el-radio :label="6">A6纸张</el-radio>
        </el-radio-group>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button v-throttle @click="treeDialogVisible = false"
          >取 消</el-button
        >
        <el-button v-throttle type="primary" @click="downloadTicketsFunc"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Loading } from 'element-ui'
import {
  check,
  checkList,
  arrangeExam,
  cancelArrangeExam,
  publishExam,
  basicList,
  arrangementExamineeCondition
} from '@/api/exam/resultPreview.js'
import {
  inquireRule,
  inquireRule2,
  downloadExamDataQuery,
  downloadTickets,
  compressDownloadTickets,
  conditionList,
} from '@/api/exam/exam.js'
import basic from './components/resultComponent/1.basic.vue'
import rules from './components/resultComponent/2.rules.vue'
import examRoom from './components/resultComponent/3.examRoom.vue'
import invigilation from './components/resultComponent/4.invigilation .vue'
import candidate from './components/resultComponent/5.Candidate.vue'
import seatArrange from './components/resultComponent/6.seatArrange.vue'
export default {
  name: 'examCheck',
  components: {
    basic,
    rules,
    examRoom,
    invigilation,
    candidate,
    seatArrange,
  },
  data() {
    return {
      // 补考
      makeup: false,
      comList: [],
      examId: '',
      errorText: [],
      // draftStatus状态 1待检测 2检查未通过 3待排考 4待发布 5已发布
      draftStatus: 1,
      arrangeStatus: false,
      rulesArrangeModel: 1,
      examDataDownloadDialogVisible: false,
      examDataDownloadList: [],
      treeDialogVisible: false,
      defaultProps: {
        children: 'childrenTree',
        label: 'name',
      },
      // 打印条件
      pdfSize: 4,
      treeList: '',
      // 选中的班级
      SysOrgSchoolClassIdList: [],
      // 所有班级
      allSysOrgSchoolClassId: [],
    }
  },
  async created() {
    this.makeup =
      this.$route.query.mainExamId ||
      (this.$route.query.makeup && JSON.parse(this.$route.query.makeup))
    if (this.$route.query.examId) {
      this.examId = this.$route.query.examId

      await this.draftStatusFunc()
      if (this.draftStatus >= 4) {
        await this.getInquireRule2()
      } else {
        await this.getInquireRule()
      }
      if (this.draftStatus == 2) {
        await this.testDataList()
      }
    }
    if (this.draftStatus >= 4) {
      this.arrangeStatus = true
    }
    this.comList = [
      { name: '基本信息', show: true, com: 'basic' },
      { name: '考试规则', show: true, com: 'rules' },
      { name: '考场安排', show: true, com: 'examRoom' },
      { name: '监考安排', show: true, com: 'invigilation' },
      { name: '考生安排', show: true, com: 'candidate' },
      { name: '考试考场座位信息', show: true, com: 'seatArrange' },
    ]
  },
  async mounted() {
    // if (this.$route.query.examId) {
    //   this.examId = this.$route.query.examId
    //   await this.draftStatusFunc()
    //   if (this.draftStatus == 2) {
    //     await this.testDataList()
    //   }
    // }
  },
  methods: {
    toggleBtn(i) {
      this.comList[i].show = !this.comList[i].show
    },
    // 检测数据
    async testDataList() {
      this.errorText = []
      await checkList(this.examId).then((res) => {
        console.log('检测数据', res)
        if (res.success) {
          res.data.forEach((val) => {
            if (val.checkStatus == 1) {
              let li = {
                name: this.filterName(val.checkStep),
                text: val.exceptionReason,
              }
              this.errorText.push(li)
            }
          })
        }
      })
    },
    // 检查
    async checkFunc() {
      // 检查
      await check(this.examId).then((res) => {
        console.log('检查', res)
      })
    },
    // 获取状态
    async draftStatusFunc() {
      await basicList(this.examId).then((res) => {
        console.log('draftStatus状态', res)
        if (res.success) {
          if (res.data.draftStatus) {
            this.draftStatus = res.data.draftStatus
          } else {
            this.draftStatus = 1
          }
        }
      })
    },
    // 检查数据按钮
    async testDataBtn() {
      await this.testDataList()
      await this.checkFunc()
      await this.draftStatusFunc()
      if (this.draftStatus == 2) {
        await this.testDataList()
      }
    },
    // 安排考试
    arrangeBtn() {
      arrangeExam(this.examId).then((res) => {
        console.log('安排考试', res)
        if (res.success) {
          this.draftStatusFunc()
        }
      })
    },
    // 排考取消
    arrangeCancelBtn() {
      cancelArrangeExam(this.examId).then((res) => {
        console.log('排考取消', res)
        if (res.success) {
          this.draftStatusFunc()
        }
      })
    },
    // 发布状态
    releaseBtn() {
      console.log('发布')
      publishExam(this.examId).then((res) => {
        if (res.success) {
          this.draftStatusFunc()
        }
      })
    },
    // 过滤模块名称
    filterName(val) {
      switch (val) {
        case 1:
          return `基本信息`
        case 2:
          return `考试规则`
        case 3:
          return `考场安排`
        case 4:
          return `监考安排`
        case 5:
          return `考生安排`
        default:
          return `checkStep为:${val}`
      }
    },
    // 获取排考方式
    async getInquireRule() {
      await inquireRule(this.examId).then((res) => {
        if (res.success) {
          this.rulesArrangeModel = res.data.arrangeModel
        }
        console.log('this.rulesArrangeModel', this.rulesArrangeModel)
      })
    },
    // 获取排考方式
    async getInquireRule2() {
      await inquireRule2(this.examId).then((res) => {
        if (res.success) {
          this.rulesArrangeModel = res.data.arrangeModel
        }
        console.log('this.rulesArrangeModel', this.rulesArrangeModel)
      })
    },
    //打开下载考试资料弹窗
    openDownloadExamDataWindows() {
      console.log('打开弹窗')
      this.examDataDownloadDialogVisible = true
    },
    //关闭下载考试资料弹窗
    closeDownloadExamDataWindows() {
      console.log('关闭弹窗')
      this.examDataDownloadDialogVisible = false
    },
    //下载考试资料请求
    downloadExamData() {
      console.log('下载考试数据参数', this.examDataDownloadList)
      if (this.examDataDownloadList.length <= 0) {
        this.$message.error('请选择下载的考试资料类型')
        return
      }
      let loadingInstance = Loading.service({
        text: '下载需要一定的时间，请耐心等待',
      })

      this.examDataDownloadDialogVisible = false
      downloadExamDataQuery(this.examId, this.examDataDownloadList).then(
        (res) => {
          // 以服务的方式调用的 Loading 需要异步关闭
          this.$nextTick(() => {
            loadingInstance.close()
          })
          if (res.success) {
            this.$message.success('下载成功')
            console.log('下载考试数据返回结果集', res.data)
            for (let item of res.data) {
              this.downloadBatchFile(this.$imageAddress(item.ossUrl))
            }
            this.examDataDownloadList = []
          } else {
            this.$message.error('下载失败：' + res.msg)
          }
        }
      )
    },
    downloadBatchFile(url) {
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none' // 防止影响页面
      iframe.style.height = 0 // 防止影响页面
      iframe.src = url
      document.body.appendChild(iframe)
      setTimeout(() => {
        iframe.remove()
      }, 5 * 60 * 1000)
    },
    // 下载准考证弹框
    downloadTicketsDialog() {
      arrangementExamineeCondition(this.examId)
        .then((res) => {
          console.log('下载准考证条件', res)
          if (res.success) {
            // 获取所有classID
            this.allSysOrgSchoolClassId = []
            this.allSysOrgSchoolClassId = res.data.sysOrgAreaList.flatMap(
              (area) =>
                area.sysOrgSchoolDTOList.flatMap((school) =>
                  school.sysOrgSchoolClassDTOList.map(
                    (classItem) => classItem.sysOrgSchoolClassId
                  )
                )
            )

            this.treeList = JSON.parse(
              JSON.stringify(res.data.sysOrgAreaList).replace(
                /sysOrgSchoolDTOList|sysOrgSchoolClassDTOList/g,
                'childrenTree'
              )
            )
            this.pdfSize = 4
            this.treeDialogVisible = true
          }
        })
        .catch((err) => {})
    },
    // 下载准考证
    downloadTicketsFunc() {
      this.SysOrgSchoolClassIdList = []
      this.SysOrgSchoolClassIdList = this.$refs.elTree
        .getCheckedNodes()
        .map((e) => e.sysOrgSchoolClassId)
      if (!this.SysOrgSchoolClassIdList.length) {
        return this.$message.info('请勾选后再下载')
      }
      let list = this.allSysOrgSchoolClassId.filter(
        (x) => !this.SysOrgSchoolClassIdList.includes(x)
      )
      let loadingInstance = Loading.service({
        text: '下载需要一定的时间，请耐心等待',
      })
      downloadTickets(this.examId, this.pdfSize, list)
        .then((res) => {
          this.treeDialogVisible = false
          // 以服务的方式调用的 Loading 需要异步关闭
          this.$nextTick(() => {
            loadingInstance.close()
          })
          if (res.success) {
            this.$message.success('下载成功')
            window.open(this.$imageAddress(res.data))
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error('下载失败：' + res.msg)
        })
    },
    // 下载 压缩准考证
    compressDownloadTicketsFunc() {
      // console.log('this', this.examId, this.rulesArrangeModel, 5)
      let loadingInstance = Loading.service({
        text: '下载需要一定的时间，请耐心等待',
      })
      compressDownloadTickets(this.examId)
        .then((res) => {
          // 以服务的方式调用的 Loading 需要异步关闭
          this.$nextTick(() => {
            loadingInstance.close()
          })
          if (res.success) {
            this.$message.success('下载成功')
            window.open(this.$imageAddress(res.data))
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error('下载失败：' + res.msg)
        })
    },
  },
  watch: {
    draftStatus(val) {
      console.log(val)
      switch (val) {
        case 2:
          this.$emit('detectFun')
          this.arrangeStatus = false
          break
        case 3:
          this.$emit('detectFun')
          this.$emit('arrangeStateChange', false)
          this.arrangeStatus = false
          break
        case 4:
          // 待发布
          this.$emit('arrangeStateChange', true)
          this.arrangeStatus = true
          break
        case 5:
          // 已发布
          this.arrangeStatus = true
          break
        default:
          this.arrangeStatus = false
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.stateBox {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  .stateBoxLeft {
    & > div {
      margin-bottom: 20px;
      color: #f56c6c;
      & > span {
        display: inline-block;
        width: 120px;
        text-align: center;
      }
      .illustrateBOX {
        display: flex;
        color: #666 !important;

        .illustrateText {
          span {
            display: block;
          }
          p {
            margin: 0;
            padding: 0 0 10px 12px;
            &:last-of-type {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
  .stateBoxRight {
    margin-right: 20px;
    .stateBoxRightTit {
      text-align: right;
      margin-bottom: 20px;
    }
    .stateBoxRightList {
      display: flex;
      .stateBoxRightLi {
        background: #f9f9f9;
        margin-left: 10px;
        padding: 16px 10px;
        width: 150px;
        span {
          font-size: 26px;
          display: block;
          text-align: center;
        }
        div {
          font-size: 15px;
          color: #8b97a7;
          display: block;
          text-align: center;
          margin: 6px 0;
        }
      }
    }
  }
}
// 下拉卡片
.cardBox {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #dfe6ec;
  margin-bottom: 20px;
  .cardTit {
    box-sizing: border-box;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px #dfe6ec solid;
    margin-bottom: 20px;
    vertical-align: middle;
    i {
      cursor: pointer;
    }
    span {
      box-sizing: border-box;
      display: inline-block;
      margin: 0 8px;
      width: 36px;
      height: 36px;
      font-size: 16px;
      line-height: 36px;
      text-align: center;
      border-radius: 50%;
      border: 1px solid #dfe6ec;
      font-weight: normal;
      margin-bottom: 8px;
    }
  }
  .cardContent {
    overflow: hidden;
  }
}
.el-pagination {
  margin-top: 10px;
}
::v-deep input::-webkit-input-placeholder {
  color: #606266;
}
.examDataDownLoadBox {
  margin-top: 20px;
  margin-left: 60px;
}
.examDataButton {
  justify-content: center;
  display: flex;
}
</style>
